<script setup lang="ts">
import { formatDollar } from '/~/utils/format'
import { BatchOrderTransaction } from '/~/composables/batch-order'
import { useLocalization } from '/~/composables/localization'

defineProps<{
  payee: BatchOrderTransaction
}>()

const { formatBsb } = useLocalization()
</script>

<template>
  <div class="flex items-center px-2">
    <div class="w-56 px-2">
      <div>{{ payee.accountName }}</div>
      <div>{{ formatBsb(payee.bsb) }} {{ payee.accountNumber }}</div>
    </div>
    <div class="px-2">
      <div>{{ payee.reference }}</div>
    </div>
    <div class="ml-auto mr-5 w-24 flex-none px-2">
      {{ formatDollar(payee.amount) }}
    </div>
  </div>
</template>
