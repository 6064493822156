<script setup lang="ts">
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useBatchOrderPayeesTable } from '/~/composables/batch-order'
import PayeeListScrollItem from './payee-list-item.vue'

const { batchOrderPayeesTable } = useBatchOrderPayeesTable()

const headerTitles = [
  { name: 'Payee', class: 'px-2 w-56' },
  { name: 'Reference*', class: 'px-2' },
  { name: 'Amount', class: 'ml-auto mr-5 w-24 flex-none px-2' },
]
</script>
<template>
  <div class="h-full overflow-hidden rounded border">
    <div class="flex border-b bg-eonx-neutral-100 p-2 font-bold">
      <div v-for="(item, i) of headerTitles" :key="i" :class="item.class">
        {{ item.name }}
      </div>
    </div>
    <div class="h-full overflow-auto pb-10">
      <payee-list-scroll-item
        v-for="(transaction, i) of batchOrderPayeesTable.transactionList.list"
        :key="i"
        :payee="transaction"
        class="border-b"
      />
      <base-loader
        v-if="
          typeof batchOrderPayeesTable.loadNext === 'function' &&
          (batchOrderPayeesTable.loader.loading ||
            batchOrderPayeesTable.pagination.hasNextPage)
        "
        v-observe-visibility="{
          callback: batchOrderPayeesTable.loadNext,
        }"
        class="py-2"
        fullwidth
      />
      <div v-else class="p-2">
        *Please note that the reference (sent to payee) will be limited to 9
        characters. For queries, contact support.
      </div>
    </div>
  </div>
</template>
